<template>
  <div>
    <div class="backgroundOV"></div>
    <div>
      <v-card outlined>
        <v-card-title class="titleLarge"
          >Operationsverständnis - Kompetenzliste</v-card-title
        >
      </v-card>
    </div>

    <br />
    <br />

    <div v-for="(n, index) in tables.length" :key="n">
      <v-card>
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr style="text-align: center">
                <td colspan="3">
                  {{ tables[index].title }}
                  <div v-if="index === 0" style="text-align: center">
                    (Hinweis: Alle Angaben auf dieser Seite werden automatisch
                    gespeichert)
                  </div>
                </td>
              </tr>
              <tr>
                <td><strong>Kompetenz: Ich kann...</strong></td>
                <td width="140px" class="no-wrap" style="text-align: center">
                  <strong>unsicher - sicher</strong>
                </td>
                <td width="35%">
                  <strong>Meine Kommentare</strong>
                </td>
              </tr>
              <tr v-for="(m, index1) in tables[index].checks.length" :key="m">
                <td>
                  {{ tables[index].checks[index1].description }}
                </td>
                <td>
                  <v-radio-group
                    dense
                    row
                    v-model="tables[index].checks[index1].level"
                    @change="saveState"
                  >
                    <v-radio
                      v-for="o in 4"
                      :key="o"
                      :value="o"
                      style="margin-right: -7px; margin-left: 0px;  justify-content: center;"
                    ></v-radio>
                  </v-radio-group>
                </td>
                <td>
                  <v-textarea
                    full-width
                    auto-grow
                    rows="0"
                    label="Kommentar"
                    @input="saveState"
                    v-model="tables[index].checks[index1].comment"
                    data-matomo-unmask
                  ></v-textarea>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
      <br />
      <br />
    </div>

    <v-btn class="white--text" x-large color="#477B7E" to="/home" style="float: right">
      <v-icon>mdi-arrow-left</v-icon>
      Zurück zur Themenübersicht
    </v-btn>

    <AppBottomNavOV back="/operationsverstaendnis/diagnose-und-foerderung" />
  </div>
</template>

<script>
import AppBottomNavOV from "@/common/AppBottomNavOV";
export default {
  components: {
    AppBottomNavOV,
  },
  mounted: function() {
    this.restoreState();
  },
  data() {
    return {
      tables: [
        {
          title: "Operationsverständnis: Grundvorstellungen",
          checks: [
            {
              description:
                "… allgemein erklären, was Grundvorstellungen im Kontext der Rechenoperationen sind.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… die drei auf FALEDIA thematisierten Grundvorstellungen zur Addition benennen und erklären, welche Bedeutung hinter diesen steckt.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… die drei auf FALEDIA thematisierten Grundvorstellungen zur Subtraktion benennen und erklären, welche Bedeutung hinter diesen steckt.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… die drei auf FALEDIA thematisierten Grundvorstellungen zur Multiplikation benennen und erklären, welche Bedeutung hinter diesen steckt.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… die zwei auf FALEDIA thematisierten Grundvorstellungen zur Division benennen und erklären, welche Bedeutung hinter diesen steckt.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… unterschiedlichen Aufgaben den entsprechenden Grundvorstellungen zuordnen.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… benennen, was Lernende in Bezug auf die Grundvorstellungen wissen sollen.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… erklären, welche Bedeutung das Wissen um die Grundvorstellungen für die Bildung eines Operationsverständnis darstellt.",
              level: 0,
              comment: "",
            },
          ],
        },
        {
          title: "Operationsverständnis: Darstellungen vernetzen",
          checks: [
            {
              description:
                "… die vier zentralen Darstellungsformen benennen und Beispiele für diese angeben.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… erklären, was mit einem Darstellungswechsel gemeint ist und diesen anhand geeigneter Beispiele verdeutlichen.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… typische Schwierigkeiten von Kindern beim Darstellungswechsel benennen und diese an konkreten Beispielen erläutern.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… passende Darstellungen zu Rechnungen zuordnen und unpassende abgrenzen.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… erklären, wann Darstellungen zueinanderpassen und wann nicht.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… erklären, welche Bedeutung das Wissen um die Darstellungsformen und deren Vernetzung für die Bildung eines Operationsverständnis darstellt.",
              level: 0,
              comment: "",
            },
          ],
        },
        {
          title: "Operationsverständnis: Aufgabenbeziehungen nutzen",
          checks: [
            {
              description:
                "… allgemein erklären, wie Beziehungen zwischen Aufgaben und Rechenoperationen genutzt werden können.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… erklären, inwiefern die vier Rechengesetze bei der Herstellung von Aufgabenbeziehungen eine besondere Rolle spielen.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… Kinderlösungen interpretieren, genutzte Rechengesetze benennen und das (Weiter)Denken der Kinder antizipieren.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… an Beispielen begründen, wann und warum die Nutzung eines Rechengesetzes sinnvoll ist.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… die Zusammenhänge zwischen den vier Rechengesetzen und entsprechenden Rechenoperationen erläutern.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… erklären, welche Bedeutung das Wissen um Aufgabenbeziehungen und Beziehungen zwischen Rechengesetzen für die Bildung eines Operationsverständnis darstellt.",
              level: 0,
              comment: "",
            },
          ],
        },
        {
          title: "Operationsverständnis: Diagnose und Förderung",
          checks: [
            {
              description:
                "… die drei zentralen Hintergrundfacetten des Operationsverständnis nennen und erklären, warum dieses Hintergrundwissen für die Diagnose und Förderung von Lernenden wichtig ist.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… Kinderdokumente hinsichtlich des Operationsverständnisses und genutzter Grundvorstellungen interpretieren.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… vorhandene Kompetenzen im Bereich des Operationsverständnisses anhand von Kinderdokumenten erkennen und beschreiben.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… Schwierigkeiten im Bereich des Operationsverständnisses anhand von Kinderdokumenten erkennen und beschreiben.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… Ursachen für Fehler im Bereich des Operationsverständnisses anhand von Kinderdokumenten begründet ableiten.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… passende Diagnoseaufgaben auswählen und die Wahl dieser begründen.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… erklären welche Relevanz die Integration unterschiedlicher Facetten des Operationsverständnisses in der Unterrichtsgestaltung darstellt.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… geeignete Aufgabenstellungen den drei zentralen Hintergrundfacetten zuordnen.",
              level: 0,
              comment: "",
            },
          ],
        },
      ],
    };
  },
  methods: {
    restoreState: function() {
      const storedId = "CheckOV";

      const restoredState = this.$store.state.falediaState
        ? this.$store.state.falediaState[storedId]
        : false;

      if (restoredState) {
        this.tables = restoredState.data.tables;
      }
      if (!this.$store.hasModule(["nested", storedId])) {
        this.$store.registerModule(["nested", storedId], {
          namespaced: true,
          state: {
            data: {},
          },
          mutations: {
            data(state, payload) {
              state.data = {
                tables: payload.tables,
              };
            },
          },
        });
        this.saveStateLocal();
      }
    },

    saveStateLocal: function() {
      const storedId = "CheckOV";
      this.$store.commit("nested/" + storedId + "/data", { ...this._data });
    },

    saveState: function() {
      this.saveStateLocal();
      //send data to server
      const token = localStorage.getItem("jwt");
      try {
        this.$http
          .post("/user/state", this.$store.state.nested, {
            headers: { Authorization: token },
          })
          .then((response) => {
            localStorage.setItem("jwt", response.data.token);
            this.$store.commit("falediaState", this.$store.state.nested);
          })
          .catch(() => {
            //if there is an error, we do not save the state
          });
      } catch (err) {
        //if there is an error, we do not save the state
      }
    },
  },
};
</script>

<style scoped>
#app .no-wrap {
  white-space: nowrap;
  overflow: auto;
}
#app .titleLarge {
  font-weight: normal;
  font-size: xx-large;
}
#app .titleSmaller {
  font-weight: normal;
  font-size: x-large;
}
#app .tdButton {
  position: absolute;
  right: -10px;
  bottom: -5px;
}
</style>
